// (function () {
//
//     document.addEventListener('click', function (event) {
//         /** @type {HTMLInputElement} switcherInputElement */
//         const switcherInputElement = event.target.closest('.switcherInput');
//         if (!switcherInputElement) {
//             return;
//         }
//
//         let checkedLabel = switcherInputElement.getAttribute('data-label-checked');
//         let label = switcherInputElement.getAttribute('data-label');
//         if (!checkedLabel && !label) {
//             return;
//         }
//
//         const labelElement = getLabelElementForInputElement(switcherInputElement);
//         if (!labelElement) {
//             return;
//         }
//
//
//     });
//
//
//
//     /**
//      * @param {HTMLInputElement} inputElement
//      * @return {HTMLLabelElement|undefined}
//      */
//     function getLabelElementForInputElement(inputElement) {
//         if (inputElement.parentElement.nodeName === 'LABEL') {
//             return inputElement.parentElement;
//         }
//
//         if (inputElement.id) {
//             return document.querySelector('label[for=' + inputElement.id + ']');
//         }
//     }
//
//     /**
//      * @param {HTMLElement} element
//      * @return string
//      */
//     function getValueFromTextChildNode(element) {
//         let value = '';
//         for (let i = 0; i < element.childNodes.length; i++) {
//             let node = element.childNodes[i];
//             if (node.nodeName === '#text') {
//                 value = node.nodeValue;
//                 break;
//             }
//         }
//         return value;
//     }
//
//     /**
//      * @param {HTMLElement} element
//      */
//     function setTextChildNodeValue(element, value) {
//         for (let i = 0; i < element.childNodes.length; i++) {
//             let node = element.childNodes[i];
//             if (node.nodeName === '#text') {
//                 node.nodeValue = value;
//                 return;
//             }
//         }
//     }
// })();

// jQuery free
"use strict";