"use strict";

(function () {
  'use strict';

  window.KMUserEditAccountControl = function () {
    jQuery(document).ready(function () {
      var forms = document.querySelectorAll("form");
      for (var i = 0; i < forms.length; ++i) {
        Nette.initForm(forms[i]);
      }
      var linksToConfirm = Array.from(document.getElementsByClassName('jsConfirm'));
      var _loop = function _loop() {
        var link = _linksToConfirm[_i];
        link.onclick = function (event) {
          if (confirm(link.getAttribute('data-confirmText')) !== true) {
            event.preventDefault();
          }
        };
      };
      for (var _i = 0, _linksToConfirm = linksToConfirm; _i < _linksToConfirm.length; _i++) {
        _loop();
      }
      window.KMBillingAddressFormContainerInit();
      window.KMUserAddressesFormContainerInit();
    });
  };
})();