"use strict";

$(function () {
  var iframe = document.getElementById('SuppliesOverviewIframe');
  if (iframe) {
    console.log('iframe');
    iframe.onload = function () {
      console.log('onload');
      var body = iframe.contentDocument.body;
      iframe.style.height = body.offsetHeight + 'px';
      console.log('resize-initial');
      var observer = new MutationObserver(function () {
        console.log('resize-by-observer');
        iframe.style.height = body.offsetHeight + 'px';
      });
      observer.observe(body, {
        attributes: true,
        childList: true,
        subtree: true
      });
    };
  }
});