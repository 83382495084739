"use strict";

(function () {
  var CLASS_KM_SPINNER_PLACE = 'km-spinner-place';
  function initSpinnerPlaceElements(rootElement) {
    var parentElements = rootElement.getElementsByClassName(CLASS_KM_SPINNER_PLACE);
    for (var i = 0; i < parentElements.length; i++) {
      var parentElement = parentElements[i];
      parentElement.innerHTML = window.km_spinner.html;
    }
  }
  window.km_spinner = {
    html: '<div class="km-spinner"><div></div></div>',
    htmlLight: '<div class="km-spinner --light"><div></div></div>',
    initSpinnerPlaceElements: initSpinnerPlaceElements
  };
  onDOMReady(function () {
    initSpinnerPlaceElements(document.body);
  });
})();

// jQuery free