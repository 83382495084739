"use strict";

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it["return"] != null) it["return"](); } finally { if (didErr) throw err; } } }; }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
(function () {
  var COOKIE_NAME = 'km_cookie_consents';
  var COOKIE_PROPERTY_JS = '_js';
  var DATA_JS = 'data-js';
  var DATA_COOKIE_CONSENTS_UPDATE_URL = 'data-cookie-consents-update-url';
  var VISIBLE_CLASS_NAME = '--visible';
  var SWITCHER_LABEL_CLASS_NAME = 'cookieConsent__sectionConsent__switcherLabel__text';
  var DATA_COOKIE_CONSENTS_DURATION_SECONDS = 'data-cookie-consents-duration-seconds';
  var DATA_COOKIE_CONSENT_CODENAMES = 'data-cookie-consent-codenames';
  var DATA_INIT = 'data-init';
  window.km_cookieConsentInit = function () {
    var form = document.getElementById('cookieConsent__form');
    if (!form || form.hasAttribute(DATA_INIT)) {
      return;
    }
    var buttonSelection = document.getElementById('cookieConsent__buttonSelection');
    var buttonGrantSelection = document.getElementById('cookieConsent__buttonGrantSelection');
    var buttonGrantNone = document.getElementById('cookieConsent__buttonGrantNone');
    var buttonGrantAll = document.getElementById('cookieConsent__buttonGrantAll');
    var sectionStorageConsent = document.getElementById('cookieConsent__sectionStorageConsent');
    var sectionSelection = document.getElementById('cookieConsent__sectionSelection');
    var consentCheckboxes = getCookieConsentCheckboxes(form);
    buttonSelection.addEventListener('click', function (event) {
      event.preventDefault();
      buttonGrantSelection.removeAttribute('hidden');
      sectionSelection.removeAttribute('hidden');
      buttonSelection.setAttribute('hidden', null);
      sectionStorageConsent.setAttribute('hidden', null);
      if (buttonGrantNone) {
        buttonGrantNone.setAttribute('hidden', null);
      }
    });
    if (buttonGrantNone) {
      buttonGrantNone.addEventListener('click', function () {
        for (var key in consentCheckboxes) {
          if (!consentCheckboxes[key].disabled) {
            consentCheckboxes[key].checked = false;
          }
        }
      });
    }
    buttonGrantAll.addEventListener('click', function () {
      for (var key in consentCheckboxes) {
        consentCheckboxes[key].checked = true;
      }
    });
    var _loop = function _loop(key) {
      consentCheckboxes[key].onchange = function () {
        var _iterator = _createForOfIteratorHelper(consentCheckboxes[key].parentElement.getElementsByClassName(SWITCHER_LABEL_CLASS_NAME)),
          _step;
        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var label = _step.value;
            if (label.classList.contains(VISIBLE_CLASS_NAME)) {
              label.classList.remove(VISIBLE_CLASS_NAME);
            } else {
              label.classList.add(VISIBLE_CLASS_NAME);
            }
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
      };
    };
    for (var key in consentCheckboxes) {
      _loop(key);
    }
    form.addEventListener('submit', function (event) {
      event.preventDefault();
      var consentData = getConsentData(consentCheckboxes);

      // If cookie was already persisted before, send the form.
      // It solves the case when a cookie is httpOnly and cannot be change in JS.
      if (form.getAttribute(DATA_JS) !== 'true') {
        fetch(form.getAttribute(DATA_COOKIE_CONSENTS_UPDATE_URL), {
          method: 'POST',
          body: JSON.stringify(consentData),
          credentials: 'same-origin',
          // To send cookies.
          redirect: 'follow',
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            // Because of Nette's isAjax()
            'Content-Type': 'application/json'
          }
        })["catch"](function (response) {
          console.info(response);
        })["catch"](function (err) {
          console.error(err);
        });
      }
      var currentTimestamp = new Date().getTime();
      var durationTimestamp = parseInt(form.getAttribute(DATA_COOKIE_CONSENTS_DURATION_SECONDS)) * 1000;
      var expirationDate = form.hasAttribute(DATA_COOKIE_CONSENTS_DURATION_SECONDS) ? new Date(currentTimestamp + durationTimestamp) : null;
      if (setCookieConsentsCookie(consentCheckboxes, expirationDate)) {
        gtag('consent', 'update', consentData);
        window.dataLayer.push({
          consent: consentData
        });
        window.km_modal.destroy(true);
      }
    });
    form.setAttribute(DATA_INIT, 1);
  };
  onDOMReady(function () {
    window.km_cookieConsentInit();
  });

  /**
   * @param {HTMLFormElement} form
   */
  function getCookieConsentCheckboxes(form) {
    var elements = form.elements;
    var result = {};
    for (var i = 0; i < elements.length; i++) {
      var item = elements.item(i);
      var found = item.name.match(/cookieConsents\[([a-z]+)\]/i);
      if (found) {
        result[found[1]] = item;
      }
    }
    return result;
  }
  function setCookieConsentsCookie(consentCheckboxes, expirationDate) {
    var cookieConsentsObject = {};
    for (var key in consentCheckboxes) {
      cookieConsentsObject[key] = consentCheckboxes[key].checked;
    }
    cookieConsentsObject[COOKIE_PROPERTY_JS] = Number(new Date());
    var base64Value = btoa(JSON.stringify(cookieConsentsObject));
    document.cookie = "".concat(COOKIE_NAME, "=").concat(base64Value, ";path=/;").concat(expirationDate ? "expires=".concat(expirationDate.toUTCString(), ";") : "");

    // Confirm that cookie was set properly. It means that httpOnly cookie (non-overwrittable) did not exist before.
    return document.cookie.indexOf(COOKIE_NAME) !== -1;
  }
  function getConsentData(consentCheckboxes) {
    var consent = {};
    for (var key in consentCheckboxes) {
      // noinspection JSUnfilteredForInLoop
      var inputElement = consentCheckboxes[key];
      var value = inputElement.checked ? 'granted' : 'denied';
      var codenames = JSON.parse(inputElement.getAttribute(DATA_COOKIE_CONSENT_CODENAMES));
      for (var i in codenames) {
        var snakeCaseKey = codenames[i].replace(/[A-Z]/g, function (letter) {
          return "_".concat(letter.toLowerCase());
        });
        consent[snakeCaseKey] = value;
      }
    }
    return consent;
  }
})();