"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it["return"] != null) it["return"](); } finally { if (didErr) throw err; } } }; }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
var TREE_NAVIGATION_CLASS_NAME = 'js-treeNavigation';
var LOADER_CLASS_NAME = 'js-treeNavigation__ajaxLoader';
var DATA_INIT = 'data-init';
jQuery(document).ready(function () {
  var treeNavigations = document.getElementsByClassName(TREE_NAVIGATION_CLASS_NAME);
  for (var i = 0; i < treeNavigations.length; i++) {
    var treeNavigation = treeNavigations[i];
    //console.log(treeNavigation);
    initialiseTreeNavigation(treeNavigation);
  }
});

// Init the tree-navigation immediately if this script is already loaded by browser.
document.addEventListener('kvik_tree_navigation_dom_ready', function (event) {
  //console.log('kvik_tree_navigation_dom_ready', event.detail, document.getElementById(event.detail));
  initialiseTreeNavigation(document.getElementById(event.detail));
});
var initialiseTreeNavigation = function initialiseTreeNavigation(treeNavigation) {
  if (treeNavigation.hasAttribute(DATA_INIT)) {
    return;
  }

  //console.log(treeNavigation);
  var classBlock = treeNavigation.getAttribute('data-class-block');
  var classModifiers = treeNavigation.getAttribute('data-class-modifiers').split(' ');
  var openerClassNameSelector = createSelectorClassName(classBlock, 'openerTemplate');
  var openerTemplate = treeNavigation.getElementsByClassName(openerClassNameSelector)[0];
  if (!openerTemplate) {
    return;
  }
  initialiseNodes(treeNavigation, openerTemplate, classBlock, classModifiers, treeNavigation.getAttribute("data-children-api-link"));
  treeNavigation.setAttribute('data-init', 1);
};
var isNodeOpened = function isNodeOpened(node) {
  return node.getAttribute('data-opened') === '1';
};
var initialiseNodes = function initialiseNodes(context, openerTemplate, classBlock, classModifiers, childrenApiLink) {
  var nodes = context.getElementsByClassName(createSelectorClassName(classBlock, 'node', 'hasChildren'));
  var _loop = function _loop() {
    var node = nodes[i];
    var opener = appendOpenerToNode(node, openerTemplate);
    opener.onclick = function () {
      var list = node.getElementsByTagName('ul')[0];
      var isNodeBeingOpened = !isNodeOpened(node);
      node.setAttribute('data-opened', isNodeBeingOpened ? '1' : '0');
      toggleOpener(opener);
      if (list) {
        list.style.display = isNodeBeingOpened ? 'block' : 'none';
      } else {
        opener.disabled = true;
        var ajaxLoader = document.createElement('div');
        ajaxLoader.className = LOADER_CLASS_NAME + ' ' + 'ajax-loader';
        ajaxLoader.innerHTML = km_spinner.html;
        node.appendChild(ajaxLoader);
        var nodeDataset = {
          id: node.getAttribute('data-id'),
          level: node.getAttribute('data-level'),
          vehicleType: node.getAttribute('data-vehicle-type'),
          linkingTargetId: node.getAttribute('data-linking-target-id'),
          linkingTargetType: node.getAttribute('data-linking-target-type')
        };
        fetchChildren(childrenApiLink, nodeDataset, function (childrenData) {
          node.removeChild(ajaxLoader);
          if (childrenData.length === 0) {
            return;
          }
          var level = parseInt(nodeDataset.level) + 1;
          list = document.createElement('ul');
          list.className = createClassName(classBlock, classModifiers, 'list', level);
          var _iterator = _createForOfIteratorHelper(childrenData),
            _step;
          try {
            for (_iterator.s(); !(_step = _iterator.n()).done;) {
              var childData = _step.value;
              var hasChildren = childData['hasChildren'];
              var childNode = document.createElement('li');
              childNode.className = createClassName(classBlock, classModifiers, 'node', level, hasChildren);
              childNode.setAttribute('data-id', childData.id);
              childNode.setAttribute('data-level', level.toString());
              if (nodeDataset.linkingTargetId !== null) {
                childNode.setAttribute('data-linking-target-id', nodeDataset.linkingTargetId);
              }
              if (nodeDataset.linkingTargetType !== null) {
                childNode.setAttribute('data-linking-target-type', nodeDataset.linkingTargetType);
              }
              if (nodeDataset.vehicleType !== null) {
                childNode.setAttribute("data-vehicle-type", nodeDataset.vehicleType);
              }
              var childNodeAnchor = document.createElement('a');
              childNodeAnchor.href = childData.url;
              childNodeAnchor.className = createClassName(classBlock, classModifiers, 'link', level, hasChildren);
              childNode.appendChild(childNodeAnchor);
              var childNodeName = document.createElement('span');
              childNodeName.className = createClassName(classBlock, classModifiers, 'name', level, hasChildren);
              childNodeName.innerText = childData.name;
              childNodeAnchor.appendChild(childNodeName);
              if (childData.tagName) {
                var childNodeTag = document.createElement('span');
                childNodeTag.className = createClassName(classBlock, classModifiers, 'tag', level, hasChildren);
                childNodeTag.className += ' tag --category';
                childNodeTag.innerText = childData.tagName;
                childNodeAnchor.appendChild(childNodeTag);
              }
              list.appendChild(childNode);
            }
          } catch (err) {
            _iterator.e(err);
          } finally {
            _iterator.f();
          }
          node.appendChild(list);
          list.style.display = 'block';
          opener.disabled = false;
          initialiseNodes(list, openerTemplate, classBlock, classModifiers, childrenApiLink);
          if (window.hasOwnProperty('onChildCategoriesInitialised')) {
            window['onChildCategoriesInitialised'](list);
          }
          if (window.hasOwnProperty('updateStickySidebar')) {
            window['updateStickySidebar']();
          }
        });
      }
    };
  };
  for (var i = 0; i < nodes.length; i++) {
    _loop();
  }
};
var createSelectorClassName = function createSelectorClassName(blockName, elementName, modifierName) {
  return blockName + (elementName ? "__".concat(elementName) : '') + (modifierName ? "--".concat(modifierName) : '');
};
var createClassName = function createClassName(classBlock, classModifiers, elementName, level, hasChildren) {
  var classBase = "".concat(classBlock, "__").concat(elementName);
  var className = "".concat(classBase);
  className += " ".concat(elementName); // BC

  for (var i = 0; i < classModifiers.length; i++) {
    className += " ".concat(classBase, "--") + classModifiers[i];
  }
  if (level) {
    className += " ".concat(classBase, "--").concat(level.toString());
    className += " ".concat(elementName, "-").concat(level.toString()); // BC
  }

  if (hasChildren) {
    className += " ".concat(classBase, "--hasChilds");
    className += " ".concat(classBase, "--hasChildren");
  }
  return className;
};
var appendOpenerToNode = function appendOpenerToNode(node, openerTemplate) {
  var openerContent = document.importNode(openerTemplate.content, true);
  node.insertBefore(openerContent, node.firstChild);
  var opener = node.getElementsByTagName('button')[0];
  var toHideChildrenIndex = isNodeOpened(node) ? 0 : 1;
  $(opener.children[toHideChildrenIndex]).hide();
  node.appendChild(opener);
  return opener;
};
var toggleOpener = function toggleOpener(opener) {
  $(opener.children[0]).toggle();
  $(opener.children[1]).toggle();
};
var fetchChildren = function fetchChildren(childrenApiLink, dataset, doOnSuccess) {
  var params = null;
  if (childrenApiLink.includes("_parentId_")) {
    childrenApiLink = childrenApiLink.replace("_parentId_", dataset.id);
  } else {
    params = _objectSpread({}, dataset);
    for (var param in params) {
      if (params.hasOwnProperty(param)) {
        if (params[param] === null) {
          delete params[param];
        }
      }
    }
    params = _objectSpread({}, params);
  }
  jQuery.get(childrenApiLink, params).done(function (data) {
    doOnSuccess(data);
  }).error(function (error) {
    console.error(error);
  });
};