"use strict";

(function (window, factory) {
  window.KM = window.KM || {};
  window.KM.ArticleVehiclesListControl = factory(window.KM, window.jQuery);
})(window, function (KM, $) {
  'use strict';

  var articleVehiclesListControl = {};
  articleVehiclesListControl.onVehicleItemsModalCloseClick = function (clickEvent) {
    clickEvent.preventDefault();
    $(".vehicleItemsModal__backdrop").remove();
    $(".vehicleItemsModal").remove();
  };
  articleVehiclesListControl.onVehicleItemClick = function (clickEvent) {
    var $vehicleItemLink = $(this);
    var $ajaxLoader = $('<div class="ajax-loader ajax-loader--vehicleProduct">' + km_spinner.html + '<div class="ajax-loader__pendingText">' + $vehicleItemLink.attr("data-vehicleProduct-pendingText") + '</div></div>');
    var $vehicleItemsModal = $vehicleItemLink.closest(".vehicleItemsModal");
    $vehicleItemsModal.find(".vehicleItemsModal__head").remove();
    $vehicleItemsModal.find(".vehicleItemsModal__body").html($ajaxLoader);
  };
  articleVehiclesListControl.onModelItemClick = function (clickEvent) {
    clickEvent.preventDefault();
    var $modelItemLink = $(this);
    var $modelItem = $modelItemLink.closest(".modelItem");
    var $modelItemChunk = $modelItemLink.closest(".modelItemChunk");
    var $vehicleItemsModal = $('<div class="vehicleItemsModal"><div class="vehicleItemsModal__body"></div></div>');
    var $vehicleItemsModalHeader = $('<div class="vehicleItemsModal__head"><h3 class="vehicleItemsModal__header">' + $modelItemLink.attr("data-loadVehicleItems-doneText") + '</h3><button class="vehicleItemsModal__close">&times;</button></div>');
    var $backdrop = $('<div class="vehicleItemsModal__backdrop"></div>');
    var $ajaxLoader = $('<div class="ajax-loader ajax-loader--loadVehicleItems">' + km_spinner.html + '<div class="ajax-loader__pendingText">' + $modelItemLink.attr("data-loadVehicleItems-pendingText") + '</div></div>');
    articleVehiclesListControl.onVehicleItemsModalCloseClick(clickEvent);
    $vehicleItemsModal.find(".vehicleItemsModal__body").html($ajaxLoader);
    $vehicleItemsModal.addClass($modelItem.is(':first-child') ? 'vehicleItemsModal--left' : 'vehicleItemsModal--right');
    if ($modelItemChunk.children().length === 1) {
      $vehicleItemsModal.addClass('vehicleItemsModal--oneModelInChunk');
    }
    $modelItemChunk.append($vehicleItemsModal);
    $modelItemChunk.append($backdrop);
    $(".vehicleItemsModal__backdrop").on("click tap", articleVehiclesListControl.onVehicleItemsModalCloseClick);
    $.get($modelItemLink.attr("data-href"), {}, function (html) {
      var $vehicleItemsModalBody = $vehicleItemsModal.find(".vehicleItemsModal__body");
      $vehicleItemsModalBody.html(html);
      $vehicleItemsModalBody.before($vehicleItemsModalHeader);
      $vehicleItemsModal.find("a").on("click tap", articleVehiclesListControl.onVehicleItemClick);
      $(".vehicleItemsModal__close").on("click tap", articleVehiclesListControl.onVehicleItemsModalCloseClick);
    });
  };
  articleVehiclesListControl.onManufacturerItemClick = function (clickEvent) {
    clickEvent.preventDefault();
    var $manufacturerItemLink = $(this);
    var $manufacturerItem = $manufacturerItemLink.closest(".manufacturerItem");
    var $manufacturerItemChunk = $manufacturerItemLink.closest(".manufacturerItemChunk");
    var $ajaxLoader = $('<div class="ajax-loader ajax-loader--loadModelItems">' + km_spinner.html + '<div class="ajax-loader__pendingText">' + $manufacturerItemLink.attr("data-loadModelItems-pendingText") + '</div></div>');
    var $manufacturerItemSelected = $(".manufacturerItem--selected");
    $(".ajax-loader").remove();
    $(".articleVehiclesList .manufacturerItem__modelItems").remove();
    if (!$manufacturerItem.hasClass("manufacturerItem--selected")) {
      $manufacturerItem.addClass("manufacturerItem--selected");
    }
    if ($manufacturerItemSelected.length > 0) {
      $manufacturerItemSelected.removeClass("manufacturerItem--selected");
    }
    if ($manufacturerItem.hasClass("manufacturerItem--selected")) {
      $manufacturerItemChunk.after($ajaxLoader);
      $.get($manufacturerItemLink.attr("data-href"), {}, function (html) {
        $ajaxLoader.after(html);
        $ajaxLoader.remove();
        $("a.modelItem__link").on("click tap", articleVehiclesListControl.onModelItemClick);
      });
    }
  };
  articleVehiclesListControl.onDocumentReady = function () {
    $(".manufacturerItem__link").on("click tap", articleVehiclesListControl.onManufacturerItemClick);
  };
  $(document).ready(articleVehiclesListControl.onDocumentReady);
  return articleVehiclesListControl;
});