"use strict";

(function () {
  var recaptchaElementsToInitOnload = [];
  var recaptchaScriptAttached = false;
  var recaptchaResolved = false;
  var recaptchaLanguageCodes = ['ar', 'af', 'am', 'hy', 'az', 'eu', 'bn', 'bg', 'ca', 'zh-HK', 'zh-CN', 'zh-TW', 'hr', 'cs', 'da', 'nl', 'en-GB', 'en', 'et', 'fil', 'fi', 'fr', 'fr-CA', 'gl', 'Value', 'ka', 'de', 'de-AT', 'de-CH', 'el', 'gu', 'iw', 'hi', 'hu', 'is', 'id', 'it', 'ja', 'kn', 'ko', 'lo', 'lv', 'lt', 'ms', 'ml', 'mr', 'mn', 'no', 'fa', 'Value', 'pl', 'pt', 'pt-BR', 'pt-PT', 'ro', 'ru', 'sr', 'si', 'sk', 'sl', 'es', 'es-419', 'sw', 'sv', 'ta', 'te', 'th', 'tr', 'uk', 'ur', 'vi', 'zu'];
  onDOMReady(function () {
    var elements = document.getElementsByClassName('recaptcha__recaptcha');
    if (elements.length) {
      recaptchaScriptAttach();
    }
    for (var i = 0; i < elements.length; i++) {
      recaptchaElementsToInitOnload.push(elements[i]);
    }
  });
  window.km_recaptchaAddElementById = function (id) {
    var element = document.getElementById(id);
    if (window.hasOwnProperty('grecaptcha')) {
      recaptchaInitElement(element);
    } else {
      recaptchaElementsToInitOnload.push(element);
      recaptchaScriptAttach();
    }
  };
  var recaptchaScriptAttach = function recaptchaScriptAttach() {
    if (!recaptchaScriptAttached) {
      var documentLang = document.documentElement.getAttribute('lang');
      var lang = recaptchaLanguageCodes.includes(documentLang) ? documentLang : 'en';
      var scriptElement = document.createElement('script');
      scriptElement.src = 'https://www.google.com/recaptcha/api.js?onload=km_recaptchaScriptOnloadCallback&render=explicit&hl=' + lang;
      scriptElement.async = true;
      document.head.appendChild(scriptElement);
      recaptchaScriptAttached = true;
    }
  };
  window.km_recaptchaScriptOnloadCallback = function () {
    for (var i = 0; i < recaptchaElementsToInitOnload.length; i++) {
      var element = recaptchaElementsToInitOnload[i];
      recaptchaInitElement(element);
    }
    recaptchaElementsToInitOnload.length = 0;
  };
  var recaptchaInitElement = function recaptchaInitElement(element) {
    window.grecaptcha.ready(function () {
      if (element.hasAttribute('data-recaptcha-init')) {
        return;
      }
      element.setAttribute('data-recaptcha-init', '1');
      window.grecaptcha.render(element);
      var badge = element.getElementsByClassName('grecaptcha-badge')[0];
      badge.style.height = '0';
      badge.style.visibility = 'hidden';
      var form = element.closest('form');
      form.addEventListener('submit', recaptchaFormSubmitListenerFactory(form));
    });
  };
  var recaptchaFormSubmitListenerFactory = function recaptchaFormSubmitListenerFactory(form) {
    return function (e) {
      // we already have reCaptcha response, or the form is invalid - or submission is prevented for some other, unknown reason
      if (recaptchaResolved || e.defaultPrevented) {
        return;
      }
      e.preventDefault();
      window.grecaptcha.execute().then(function (token) {
        recaptchaResolved = true;

        // reCaptcha token expires after 2 minutes; make it 5 seconds earlier just in case network is slow
        setTimeout(function () {
          recaptchaResolved = false;
        }, (2 * 60 - 5) * 1000);
        var inputs = document.getElementsByClassName('g-recaptcha-response');
        for (var i = 0; i < inputs.length; i++) {
          inputs[i].value = token;
        }
        form.submit();
      });
    };
  };
})();

// jQuery free