"use strict";

(function () {
  var ID_MODAL_BACKDROP = 'modal__backdrop';
  var ID_MODAL_DIALOG = 'modal__dialog';
  var ID_MODAL_CLOSE = 'modal__close';
  var ID_MODAL_LOADING_PANE = 'modal__loadingPane';
  var CLASS_MODAL_DIALOG = 'modal__dialog';
  var CLASS_OPEN = '--open';
  var CLASS_LOADER = '--loader';
  var ATTR_HIDDEN = 'hidden';
  var EVENT_KM_MODAL_SHOW_MODAL = 'km_modal_show_modal';
  var EVENT_KM_MODAL_SHOW_MODAL_BEFORE = 'km_modal_show_modal_before';
  var EVENT_KM_MODAL_DESTROY = 'km_modal_destroy';
  var DATA_MODAL_SEND_ONCLOSE = 'data-modal-send-onclose';
  var DATA_MODAL_CLOSEABLE = 'data-modal-closeable';
  var DATA_MODAL_CLOSEABLE_BY_BACKDROP_CLICK = 'data-modal-closeable-by-backdrop-click';
  var openDialogElement = null;
  var isClosable = true;
  var isClosableByBackdropClick = true;
  var KM_MODAL_SIZES = {
    "default": 'default',
    full: 'full'
  };
  function createCloseElement(dialogElement) {
    var closeElement = document.createElement('button');
    closeElement.id = ID_MODAL_CLOSE;
    closeElement.addEventListener('click', function () {
      return destroy();
    });
    dialogElement.appendChild(closeElement);
    return closeElement;
  }
  function createBackdropElement(dialogElement, isClosableByBackdropClick) {
    var backdropElement = document.createElement('div');
    backdropElement.id = ID_MODAL_BACKDROP;
    if (isClosableByBackdropClick) {
      backdropElement.addEventListener('click', function () {
        return destroy();
      });
    }
    dialogElement.parentNode.insertBefore(backdropElement, dialogElement);
    return backdropElement;
  }
  function createLoadingPaneElement(dialogElement) {
    var loadingPaneElement = document.createElement('div');
    loadingPaneElement.id = ID_MODAL_LOADING_PANE;
    loadingPaneElement.setAttribute(ATTR_HIDDEN, true);
    loadingPaneElement.innerHTML = km_spinner.html;
    dialogElement.appendChild(loadingPaneElement);
    return loadingPaneElement;
  }
  function initDismissElements(dialogElement) {
    var dismissElements = dialogElement.querySelectorAll('*[data-dismiss=modal]');
    for (var i = 0; i < dismissElements.length; i++) {
      dismissElements[i].addEventListener('click', function (event) {
        event.preventDefault();
        destroy();
      });
    }
  }
  function closeIfClosable() {
    if (isClosable) {
      destroy();
    }
    return !openDialogElement;
  }
  function destroy() {
    var destroyImmediately = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
    if (!destroyImmediately) {
      var _openDialogElement;
      var element = (_openDialogElement = openDialogElement) === null || _openDialogElement === void 0 ? void 0 : _openDialogElement.querySelector("[".concat(DATA_MODAL_SEND_ONCLOSE, "]"));
      if (element) {
        window.kvik_ajax.sendRequestFromElement(element);
      }
    }
    var _loop = function _loop() {
      var id = _arr[_i];
      var element = document.getElementById(id);
      if (!element) {
        return "continue";
      }
      if (element.id === ID_MODAL_DIALOG) {
        var eventDetail = {
          dialogElement: element
        };
        setTimeout(function () {
          document.dispatchEvent(new CustomEvent(EVENT_KM_MODAL_DESTROY, {
            detail: eventDetail
          }));
        }, 200);
      }
      if (destroyImmediately) {
        element.parentElement.removeChild(element);
      } else {
        element.classList.remove(CLASS_OPEN);
        setTimeout(function () {
          element.parentElement.removeChild(element);
        }, 250);
      }
    };
    for (var _i = 0, _arr = [ID_MODAL_BACKDROP, ID_MODAL_DIALOG]; _i < _arr.length; _i++) {
      var _ret = _loop();
      if (_ret === "continue") continue;
    }
    openDialogElement = null;
  }
  function getElementInnerHeight(element) {
    var computedStyles = getComputedStyle(element);
    return element.clientHeight - parseInt(computedStyles.paddingTop) - parseInt(computedStyles.paddingBottom);
  }
  function showDialog(dialogElement) {
    var _config$isCloseable, _config$isCloseable2;
    var config = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var wasOpen = openDialogElement && !openDialogElement.classList.contains(CLASS_LOADER);
    destroy(true);
    isClosable = (_config$isCloseable = config.isCloseable) !== null && _config$isCloseable !== void 0 ? _config$isCloseable : dialogElement.hasAttribute(DATA_MODAL_CLOSEABLE) ? Boolean(parseInt(dialogElement.getAttribute(DATA_MODAL_CLOSEABLE))) : true;
    isClosableByBackdropClick = (_config$isCloseable2 = config.isCloseable) !== null && _config$isCloseable2 !== void 0 ? _config$isCloseable2 : dialogElement.hasAttribute(DATA_MODAL_CLOSEABLE_BY_BACKDROP_CLICK) ? Boolean(parseInt(dialogElement.getAttribute(DATA_MODAL_CLOSEABLE_BY_BACKDROP_CLICK))) : true;
    dialogPolyfill.registerDialog(dialogElement);
    document.body.appendChild(dialogElement);
    dialogElement.id = ID_MODAL_DIALOG;
    if (config.classModifier) {
      dialogElement.classList.add(config.classModifier);
    }
    if (config.size) {
      dialogElement.classList.add('--size-' + config.size);
    }
    if (config.isPositionRelative) {
      dialogElement.classList.add('--position-relative');
    }
    if (wasOpen) {
      dialogElement.classList.add(CLASS_OPEN);
    }
    dialogElement.showModal();

    // Because AdBlock sets `display: none` on `class=cookieConsent`. Therefore the <dialog> have hidden children so zero height.
    if (getElementInnerHeight(dialogElement) <= 0) {
      console.warn('Destroying modal <dialog> because its inner size is zero.', dialogElement);
      destroy(true);
      return;
    }
    setTimeout(function () {
      dialogElement.classList.add(CLASS_OPEN);
    }, 50);
    createBackdropElement(dialogElement, isClosableByBackdropClick);
    createLoadingPaneElement(dialogElement);
    if (isClosable) {
      createCloseElement(dialogElement);
    }
    if (isClosableByBackdropClick) {
      dialogElement.addEventListener('click', function (event) {
        var rect = dialogElement.getBoundingClientRect();
        var isInDialog = rect.height === 0 && rect.width === 0 || rect.top <= event.clientY && event.clientY <= rect.top + rect.height && rect.left <= event.clientX && event.clientX <= rect.left + rect.width;
        if (!isInDialog && event.isTrusted) {
          destroy();
        }
      });
    }
    initDismissElements(dialogElement);
    openDialogElement = dialogElement;
    var eventDetail = {
      dialogElement: dialogElement
    };
    if (config && config.dispatchEvent) {
      document.dispatchEvent(new CustomEvent(EVENT_KM_MODAL_SHOW_MODAL_BEFORE, {
        detail: eventDetail
      }));
      setTimeout(function () {
        if (config && config.callback) {
          config.callback(eventDetail);
        }
        document.dispatchEvent(new CustomEvent(EVENT_KM_MODAL_SHOW_MODAL, {
          detail: eventDetail
        }));
      }, 300);
    }
  }
  function isDialogElement(element) {
    return element instanceof HTMLElement && element.localName === 'dialog';
  }
  function showModal(content) {
    var config = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
      dispatchEvent: true
    };
    var dialogElement = document.createElement('dialog');
    var evaluateScripts = false;
    if (content instanceof Element) {
      dialogElement.appendChild(content);
    } else {
      dialogElement.innerHTML = content;
      evaluateScripts = true;
    }
    if (isDialogElement(dialogElement.firstChild)) {
      dialogElement = dialogElement.firstChild;
    }
    document.body.appendChild(dialogElement);
    if (evaluateScripts) {
      var scripts = dialogElement.getElementsByTagName('script');
      for (var i = 0; i < scripts.length; i++) {
        eval(scripts[i].textContent);
      }
    }
    showDialog(dialogElement, config);
  }
  function showLoader() {
    var loadingPaneElement = document.getElementById(ID_MODAL_LOADING_PANE);
    if (loadingPaneElement) {
      loadingPaneElement.removeAttribute(ATTR_HIDDEN);
    } else {
      showModal(km_spinner.htmlLight, {
        isCloseable: false,
        classModifier: CLASS_LOADER,
        dispatchEvent: false
      });
    }
  }
  function isLoaderShown() {
    if (!openDialogElement) {
      return false;
    }
    if (openDialogElement.classList.contains(CLASS_LOADER)) {
      return true;
    }
    var loadingPaneElement = document.getElementById(ID_MODAL_LOADING_PANE);
    return loadingPaneElement && !loadingPaneElement.hidden;
  }
  function loadFromDOM() {
    var dialogElement = document.getElementsByClassName(CLASS_MODAL_DIALOG)[0];
    if (dialogElement) {
      showModal(dialogElement);
    }
  }
  window.km_modal = {
    showLoader: showLoader,
    showModal: showModal,
    destroy: destroy,
    isLoaderShown: isLoaderShown,
    EVENT_KM_MODAL_SHOW_MODAL: EVENT_KM_MODAL_SHOW_MODAL,
    EVENT_KM_MODAL_SHOW_MODAL_BEFORE: EVENT_KM_MODAL_SHOW_MODAL_BEFORE,
    EVENT_KM_MODAL_DESTROY: EVENT_KM_MODAL_DESTROY,
    SIZES: KM_MODAL_SIZES
  };
  onDOMReady(function () {
    loadFromDOM();
    document.addEventListener('keyup', function (event) {
      if (event.key === 'Escape' && openDialogElement && closeIfClosable()) {
        event.stopPropagation();
        event.preventDefault();
      }
    });
    window.addEventListener('pageshow', function () {
      if (isLoaderShown()) {
        destroy(true);
      }
    });
  });
})();

// jQuery free