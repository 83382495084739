"use strict";

window.KMUserAddressesFormContainerInit = function () {
  var initBillingDetailsFilledInCheckbox = function initBillingDetailsFilledInCheckbox($form) {
    var $checkbox = $("#km-userAddressesFormContainer-billingAddressCheckbox", $form);
    $checkbox.unbind('change').change(function () {
      var $tbody = $checkbox.closest("tbody");
      var $tr = $checkbox.closest("tr");
      var $elements = $("tr", $tbody).not($tr);
      if ($checkbox.is(":checked")) {
        $elements.show();
      } else {
        $.each($elements, function (index, element) {
          element.setAttribute('style', 'display:none !important');
        });
      }
    }).triggerHandler('change');
  };
  var initDynamicPlaceholders = function initDynamicPlaceholders($form) {
    var valToPlaceholder = {
      "#km-contactAddressFormContainer-personName-givenName": "#km-deliveryAddressFormContainer-personName-givenName",
      "#km-contactAddressFormContainer-personName-familyName": "#km-deliveryAddressFormContainer-personName-familyName",
      "#tel-national": "#km-deliveryAddressFormContainer-phoneNumber",
      "#email": "#km-deliveryAddressFormContainer-emailAddress"
    };
    jQuery.each(valToPlaceholder, function (from, to) {
      var $from = $(from, $form);
      var $to = $(to, $form);
      $from.on('change keyup', function () {
        $to.attr('placeholder', $from.val());
      });
      $from.triggerHandler('change');
    });

    // Contact PhoneNumber-CountryPrefix > Delivery PhoneNumber-CountryPrefix
    var countryPrefixFrom = $("#tel-national-countryPrefix", $form);
    var countryPrefixTo = $("#km-deliveryAddressFormContainer-phoneNumber-countryPrefix", $form);
    countryPrefixFrom.on('change', function () {
      countryPrefixTo.val(countryPrefixFrom.val());
    });
    countryPrefixFrom.triggerHandler('change');
  };
  var $form = $('.km-userAddressesFormContainer-form').first();
  if ($form.length === 1) {
    initBillingDetailsFilledInCheckbox($form);
    initDynamicPlaceholders($form);
  }
};
$(document).ready(function () {
  window.KMUserAddressesFormContainerInit();
});