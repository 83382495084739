"use strict";

// jQuery

(function (window, factory) {
  if (!window.jQuery.fn.scrollTo) {
    throw new Error("KvikyMart's Nette.addError requires jQuery.scrollTo");
  }
  window.Nette = window.Nette || {};
  factory(window.Nette);
})(window, function (Nette) {
  Nette.addError = function (elem, message) {
    var $elem = $(elem);
    var $tooltip = $("<div class='kvikymart-error-tooltip'>" + message + "</div>");
    var left = $elem.offset().left;
    var top = $elem.offset().top + $elem.outerHeight();
    if ($elem.attr('type') != 'checkbox' && $elem.attr('type') != 'radio') left += 5;
    $tooltip.appendTo("body").css({
      'left': left + 'px',
      'top': top + 'px'
    });
    $tooltip.click(function () {
      $tooltip.remove();
    });
    setTimeout(function () {
      $("body").click(function () {
        if ($tooltip) {
          $tooltip.remove();
        }
      });
    }, 300);
    $elem.on('input keydown change select2:open', function () {
      $tooltip.remove();
    });
    $.scrollTo($elem.parent(), 400, {
      offset: -70,
      onAfter: function onAfter() {
        $elem.focus();
      }
    });
  };
});