"use strict";

onDOMReady(function () {
  var payForm = document.getElementById('frm-payment-payForm');
  if (!payForm) {
    return;
  }

  // Commented out because of UA and GA4 are EventSubscriber (isActive === false). It doesn't work consistently across these two features and shops.
  //
  // var isPurchase = false;
  // for (var i = 0; i < window.dataLayer.length; i++) {
  //     var el = window.dataLayer[i];
  //     if (el && el[1] && el[1] === 'purchase') {
  //         isPurchase = true;
  //         break;
  //     }
  // }
  //
  // if (!isPurchase) {
  //     return;
  // }

  var buttons = document.getElementsByClassName('kvikymart-shopping-cart-submit');
  if (!buttons.length) {
    return;
  }
  var submitButton = buttons[0];
  var ajaxLoader = document.createElement('div');
  ajaxLoader.innerHTML = km_spinner.html;
  submitButton.parentNode.append(ajaxLoader);
  submitButton.parentElement.style.alignItems = "flex-end";
  submitButton.parentElement.style.display = "flex";
  submitButton.style.flexBasis = "calc(100% - 100px)";
  payForm.addEventListener("submit", function () {
    submitButton.setAttribute("disabled", "true");
  });
  setTimeout(function () {
    submitButton.click();
  }, 1500);
});