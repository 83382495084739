"use strict";

// jQuery free

(function () {
  function onDOMReady(callbackFunc) {
    if (document.readyState !== 'loading') {
      // Document is already ready, call the callback directly
      callbackFunc();
    } else if (document.addEventListener) {
      // All modern browsers to register DOMContentLoaded
      document.addEventListener('DOMContentLoaded', callbackFunc);
    } else {
      // Old IE browsers
      document.attachEvent('onreadystatechange', function () {
        if (document.readyState === 'complete') {
          callbackFunc();
        }
      });
    }
  }
  window.onDOMReady = onDOMReady;
})();