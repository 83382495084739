"use strict";

/*
 *  FRONT: Order Form: Full-fill company detail in form by Company Reg. No.
 */

window.KMBillingAddressFormContainerInit = function () {
  // Order form fields
  var regNumberInput = $('#frm-orderDetail-contactBillingDeliveryDetails-form-billingCompanyIdentification-companyRegNumber,' +
  // admin order detail
  '#frm-billingCompanyIdentification-companyRegNumber,' +
  // front shopping cart
  '#km-billingAddressFormContainer-companyIdentification-companyRegNumber').first(); // front registration, front edit account, admin customer detail

  var vatNumInput = $('#frm-orderDetail-contactBillingDeliveryDetails-form-billingCompanyIdentification-vatIdNumber,' +
  // admin order detail
  '#frm-deliveryPaymentAddress-addressForm-billingCompanyIdentification-vatIdNumber, ' +
  // front shopping cart
  '#km-billingAddressFormContainer-companyIdentification-vatIdNumber').first(); // front registration, front edit account, admin customer detail

  var taxNumInput = $('#frm-orderDetail-contactBillingDeliveryDetails-form-billingCompanyIdentification-taxIdNumber,' +
  // admin order detail
  '#frm-deliveryPaymentAddress-addressForm-billingCompanyIdentification-taxIdNumber, ' +
  // front shopping cart
  '#km-billingAddressFormContainer-companyIdentification-taxIdNumber').first(); // front registration, front edit account, admin customer detail

  var customerInput = $('#frm-orderDetail-contactBillingDeliveryDetails-form-billingCompanyIdentification-companyName,' +
  // admin order detail
  '#frm-deliveryPaymentAddress-addressForm-billingCompanyIdentification-companyName,' +
  // front shopping cart
  '#km-billingAddressFormContainer-companyIdentification-companyName').first(); // front registration, front edit account, admin customer detail

  var countryCodeInput = $('#frm-orderDetail-contactBillingDeliveryDetails-form-billingPostalAddress-country,' +
  // admin order detail
  '#frm-deliveryPaymentAddress-addressForm-billingPostalAddress-country,' +
  // front shopping cart
  '#km-billingAddressFormContainer-postalAddress-country').first(); // front registration, front edit account, admin customer detail

  var streetInput = $('#frm-orderDetail-contactBillingDeliveryDetails-form-billingPostalAddress-streetAndHouseNumber,' +
  // admin order detail
  '#frm-deliveryPaymentAddress-addressForm-billingPostalAddress-streetAndHouseNumber, ' +
  // front shopping cart
  '#km-billingAddressFormContainer-postalAddress-streetAndHouseNumber').first(); // front registration, front edit account, admin customer detail

  var cityInput = $('#frm-orderDetail-contactBillingDeliveryDetails-form-billingPostalAddress-city,' +
  // admin order detail
  '#frm-deliveryPaymentAddress-addressForm-billingPostalAddress-city, ' +
  // front shopping cart
  '#km-billingAddressFormContainer-postalAddress-city').first(); // front registration, front edit account, admin customer detail

  var postalCodeInput = $('#frm-orderDetail-contactBillingDeliveryDetails-form-billingPostalAddress-postalCode,' +
  // admin order detail
  '#frm-deliveryPaymentAddress-addressForm-billingPostalAddress-postalCode,' +
  // front shopping cart
  '#km-billingAddressFormContainer-postalAddress-postalCode').first(); // front registration, front edit account, admin customer detail

  var inputs = [customerInput, vatNumInput, streetInput, cityInput, postalCodeInput, taxNumInput];
  var submitButton = $('#frm-orderDetail-contactBillingDeliveryDetails-form-billingCompanyIdentification-companyRegNumber-autofillLink,' +
  // admin order detail
  '#frm-billingCompanyIdentification-companyRegNumber-autofillLink,' +
  // front shopping cart
  '#km-billingAddressFormContainer-companyIdentification-autofill > a').first(); // front registration, front edit account, admin customer detail

  var loadingPhrase = regNumberInput.data('autofill-loading-phrase');
  var regNumberNotFoundPhrase = regNumberInput.data('autofill-reg-number-not-found-phrase');
  var countryNotSupportedPhrase = regNumberInput.data('autofill-country-not-supported-phrase');
  var requiredPhrase = regNumberInput.data('autofill-required-phrase');
  var endpoint = regNumberInput.data('autofill-endpoint');
  var autofillSupportedCountryCodes = ['CZ', 'SK', 'FI', 'GB'];
  var isLoading = false;
  if (!window.KM) {
    window.Nette.addError = function (elem, message) {
      alert(message);
    };
  }
  var companyInfoCheckboxes = $('#frm-deliveryPaymentAddress-addressForm-billingDetailsFilledIn,' + '#km-userAddressesFormContainer-billingAddressCheckbox').first();
  var companyInfoCheckbox = companyInfoCheckboxes[0];
  var vatNumInputToggle = function vatNumInputToggle() {
    var _countryCodeInput$dat;
    var isChecked = companyInfoCheckbox ? companyInfoCheckbox.checked : false;
    if (!((_countryCodeInput$dat = countryCodeInput.data('vat-id-country-codes')) !== null && _countryCodeInput$dat !== void 0 ? _countryCodeInput$dat : []).includes(countryCodeInput.val())) {
      if (companyInfoCheckbox && isChecked || !companyInfoCheckbox) {
        vatNumInput.parent().closest('tr').hide();
        vatNumInput.prop('disabled', true);
        vatNumInput.val('');
      }
    } else {
      if (companyInfoCheckbox && isChecked || !companyInfoCheckbox) {
        vatNumInput.parent().closest('tr').show();
        vatNumInput.prop('disabled', false);
      }
    }
  };
  var taxIdInputToggle = function taxIdInputToggle() {
    var _countryCodeInput$dat2;
    var isChecked = companyInfoCheckbox ? companyInfoCheckbox.checked : false;
    if (!((_countryCodeInput$dat2 = countryCodeInput.data('tax-id-country-codes')) !== null && _countryCodeInput$dat2 !== void 0 ? _countryCodeInput$dat2 : []).includes(countryCodeInput.val())) {
      if (companyInfoCheckbox && isChecked || !companyInfoCheckbox) {
        taxNumInput.parent().closest('tr').hide();
        taxNumInput.prop('disabled', true);
        taxNumInput.val('');
      }
    } else {
      if (companyInfoCheckbox && isChecked || !companyInfoCheckbox) {
        taxNumInput.parent().closest('tr').show();
        taxNumInput.prop('disabled', false);
      }
    }
  };
  var autofillLinkToggle = function autofillLinkToggle() {
    var isChecked = companyInfoCheckbox ? companyInfoCheckbox.checked : false;
    if (autofillSupportedCountryCodes.includes(countryCodeInput.val())) {
      if (companyInfoCheckbox && isChecked || !companyInfoCheckbox) {
        submitButton.parent().closest('tr').show();
      }
    } else {
      if (companyInfoCheckbox && isChecked || !companyInfoCheckbox) {
        submitButton.parent().closest('tr').hide();
      }
    }
  };
  var isReverseChargeAppliedToggle = function isReverseChargeAppliedToggle() {
    var isChecked = companyInfoCheckbox ? companyInfoCheckbox.checked : false;
    var isReverseChargeAppliedCheckbox = document.getElementById('frm-deliveryPaymentAddress-addressForm-billingCompanyIdentification-isReverseChargeApplied');
    if (!isReverseChargeAppliedCheckbox) {
      return;
    }
    var isReverseChargeAppliedCheckedValid = window.Nette.validateControl(isReverseChargeAppliedCheckbox, null, true, {
      value: true
    });
    var isReverseChargeAppliedUncheckedValid = window.Nette.validateControl(isReverseChargeAppliedCheckbox, null, true, {
      value: false
    });
    if (companyInfoCheckbox && isChecked || !companyInfoCheckbox) {
      if (isReverseChargeAppliedCheckedValid === true && isReverseChargeAppliedUncheckedValid === false) {
        isReverseChargeAppliedCheckbox.parentElement.closest("tr").style.removeProperty("display");
        isReverseChargeAppliedCheckbox.checked = true;
      } else if (isReverseChargeAppliedCheckedValid && isReverseChargeAppliedUncheckedValid) {
        isReverseChargeAppliedCheckbox.parentElement.closest("tr").style.removeProperty("display");
        isReverseChargeAppliedCheckbox.checked = false;
      } else {
        isReverseChargeAppliedCheckbox.parentElement.closest("tr").style.setProperty("display", "none");
        isReverseChargeAppliedCheckbox.checked = false;
      }
    }
  };
  if (companyInfoCheckbox) {
    companyInfoCheckbox.onchange = function () {
      setTimeout(function () {
        vatNumInputToggle();
        taxIdInputToggle();
        autofillLinkToggle();
        isReverseChargeAppliedToggle();
      }, 100);
    };
  }
  countryCodeInput.change(autofillLinkToggle);
  countryCodeInput.change(vatNumInputToggle);
  countryCodeInput.change(taxIdInputToggle);
  countryCodeInput.change(isReverseChargeAppliedToggle);
  autofillLinkToggle();
  vatNumInputToggle();
  taxIdInputToggle();
  isReverseChargeAppliedToggle();
  submitButton.on('click', function () {
    if (!window.Nette.validateControl(regNumberInput)) {
      return;
    }
    var regNumValue = regNumberInput.val();
    var countryCodeValue = countryCodeInput.val();
    if (!regNumValue) {
      window.Nette.addError(regNumberInput, requiredPhrase);
      return;
    }
    $.ajax({
      url: endpoint + "?countryCode=".concat(countryCodeValue, "&companyRegistrationNumber=").concat(regNumValue),
      beforeSend: function beforeSend() {
        if (isLoading) {
          return false;
        }
        stateLoading();
      },
      error: function error(xhr) {
        stateFinishedLoading();
        window.Nette.addError(regNumberInput, regNumberNotFoundPhrase);
      },
      success: function success(data) {
        // success: function(data, textStatus, xhr){
        stateFinishedLoading();
        writeDataToInputs(data);
      }
    });
  });
  function stateLoading() {
    isLoading = true;
    appendLoadingIndicator();
    inputs.forEach(function (field) {
      if (field.val() === '') {
        field.attr('placeholder', loadingPhrase);
      }
    });
  }
  function appendLoadingIndicator() {
    var spinner;
    if (window.km_spinner) {
      spinner = $(window.km_spinner.html);
    } else {
      spinner = $(window.KMA.LoadingPane.getSpinnerHtml());
    }
    spinner.css({
      margin: 0
    });
    var spinnerWrapper = $('<div class="ajax-loader ajaxLoader billingAddressFormContainerLoadingSpinner"></div>');
    spinnerWrapper.append(spinner);
    submitButton.after(spinnerWrapper);
    var scale = submitButton.outerHeight() / spinner.outerHeight();
    // console.log(submitButton.outerHeight());
    // console.log(spinner.outerHeight());
    // console.log(scale);

    spinnerWrapper.css({
      display: 'inline-block',
      height: submitButton.outerHeight() + 'px',
      verticalAlign: 'top',
      transform: 'scale(' + scale + ')',
      transformOrigin: '0 0'
    });
  }
  function removeLoadingIndicator() {
    $('.billingAddressFormContainerLoadingSpinner').remove();
  }
  function stateFinishedLoading() {
    isLoading = false;
    removeLoadingIndicator();
    inputs.forEach(function (field) {
      field.attr('placeholder', '');
    });
  }
  function writeDataToInputs(data) {
    if (data.companyName) {
      customerInput.val(data.companyName);
    }
    if (data.taxIdNumber) {
      taxNumInput.val(data.taxIdNumber);
    }
    if (data.vatIdNumber) {
      vatNumInput.val(data.vatIdNumber);
    }
    if (data.streetAndHouseNumber) {
      streetInput.val(data.streetAndHouseNumber);
    }
    if (data.city) {
      cityInput.val(data.city);
    }
    if (data.postalCode) {
      postalCodeInput.val(data.postalCode);
    }
  }
};
jQuery(document).ready(function () {
  window.KMBillingAddressFormContainerInit();
});
document.addEventListener('KMDeliveryPaymentAddressControl.addressFormLoaded', function () {
  window.KMBillingAddressFormContainerInit();
});