"use strict";

$(function () {
  var url = document.URL;
  var hash = url.substring(url.indexOf('#'));
  var clickHandler = function clickHandler() {
    var $anchor = $(this);
    var href = $anchor.attr('href');
    location.hash = href;
    $(".tabContent--active").removeClass('tabContent--active');
    $("#tabContent-".concat(href.substr(1))).addClass('tabContent--active');
    $(".tabRouter .tabLink--selected").removeClass('tabLink--selected');
    $anchor.addClass('tabLink--selected');
  };
  $(".tabRouter .tabLink").each(function (key, val) {
    $(val).click(clickHandler);
    if (hash === $(val).attr('href')) {
      $(val).click();
    }
  });
});