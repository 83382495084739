"use strict";

var CLASS_NAMES = {
  userOrder: 'userOrder',
  userOrderOpenIcon: 'userOrderOpenIcon',
  userOrderDetail: 'userOrderDetail'
};
var OPENED_CLASS_NAMES = {
  userOrder: 'userOrder--opened',
  userOrderOpenIcon: 'userOrderOpenIcon--opened',
  userOrderDetail: 'userOrderDetail--visible'
};
$(function () {
  var _loop = function _loop() {
    var userOrder = _Array$from[_i];
    userOrder.onclick = function () {
      var $userOrder = $(userOrder);
      var $userOrderOpenIcon = $(userOrder.getElementsByClassName(CLASS_NAMES.userOrderOpenIcon));
      var $userOrderDetail = $(userOrder.nextElementSibling);
      if ($userOrder.hasClass(OPENED_CLASS_NAMES.userOrder)) {
        $userOrder.removeClass(OPENED_CLASS_NAMES.userOrder);
        $userOrderOpenIcon.removeClass(OPENED_CLASS_NAMES.userOrderOpenIcon);
        $userOrderDetail.removeClass(OPENED_CLASS_NAMES.userOrderDetail);
      } else {
        $userOrder.addClass(OPENED_CLASS_NAMES.userOrder);
        $userOrderOpenIcon.addClass(OPENED_CLASS_NAMES.userOrderOpenIcon);
        $userOrderDetail.addClass(OPENED_CLASS_NAMES.userOrderDetail);
      }
    };
  };
  for (var _i = 0, _Array$from = Array.from(document.getElementsByClassName(CLASS_NAMES.userOrder)); _i < _Array$from.length; _i++) {
    _loop();
  }
});